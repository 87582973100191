
import * as Sentry from '@sentry/vue'
import { Component } from 'vue-property-decorator'
import TeamMemberCard from '@/components/team/member_card.vue'
import TeamNav from '@/components/team/nav.vue'
import Team from '@/interfaces/team'
import TeamViewMixin from '@/mixins/team_view_mixin'

@Component({
  components: {
    TeamMemberCard,
    TeamNav,
  },
})
export default class TeamOverview extends TeamViewMixin {
  loading = true

  get url(): string {
    return `/backend/api/team/${this.teamId}/`
  }

  created(): void {
    this.fetchTeam(false)
  }

  async fetchTeam(reload: boolean): Promise<void> {
    // Load the team data from the API
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the JSON into a team and save it
        this.team = (await response.json()) as Team
        this.loading = false
        if (reload) this.$forceUpdate()
        document.title = `${this.team.name} - Savage Aim`
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Team.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async load(): Promise<void> {
    this.fetchTeam(true)
  }
}
