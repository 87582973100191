
import { Component, Vue, Prop } from 'vue-property-decorator'
import BISTable from '@/components/bis_table.vue'
import LeaveTeam from '@/components/modals/confirmations/leave_team.vue'
import TeamMember from '@/interfaces/team_member'

@Component({
  components: {
    BISTable,
  },
})
export default class TeamMemberCard extends Vue {
  active = false

  @Prop()
  details!: TeamMember

  @Prop()
  maxItemLevel!: number

  @Prop()
  teamId!: number

  get displayFooter(): boolean {
    // Check all the link cases in the footer and if none of them are true, we don't want to render the card footer at all
    return (
      this.details.bis_list.external_link != null
      || this.owner
    )
  }

  leave(): void {
    this.$modal.show(LeaveTeam, { details: this.details, teamId: this.teamId })
  }

  // Flag stating whether the logged in user is the owner of the member on this card
  get owner(): boolean {
    return this.$store.state.user.id === this.details.character.user_id
  }

  toggleDropdown(): void {
    this.active = !this.active
  }
}
