
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TeamNav extends Vue {
  @Prop()
  isLead!: boolean

  @Prop()
  teamId!: string

  get mainUrl(): string {
    return `/team/${this.teamId}/`
  }

  get lootUrl(): string {
    return `/team/${this.teamId}/loot/`
  }

  get managementUrl(): string {
    return `/team/${this.teamId}/management/`
  }

  get settingsUrl(): string {
    return `/team/${this.teamId}/settings/`
  }

  isActive(path: string): boolean {
    // Return a flag indicating whether the supplied url is the current one
    return this.$route.path === path
  }
}
