
import { Component, Vue, Prop } from 'vue-property-decorator'
import BISList from '@/interfaces/bis_list'
import Gear from '@/interfaces/gear'

@Component
export default class BISTable extends Vue {
  // Allows rendering other team member's cards with the kick button below
  @Prop({ default: null })
  itemLevel!: number | null

  @Prop()
  list!: BISList

  private leftShown = false

  private rightShown = false

  get maxItemLevel(): number {
    if (this.itemLevel !== null) return this.itemLevel
    // Use bis mainhand item level, since we can assume this will *probably* be the raid weapon
    return this.list.bis_mainhand.item_level
  }

  get lhs(): Element {
    return this.$refs.lhs as Element
  }

  get lhsTab(): Element {
    return this.$refs.lhsTab as Element
  }

  get rhs(): Element {
    return this.$refs.rhs as Element
  }

  get rhsTab(): Element {
    return this.$refs.rhsTab as Element
  }

  // Method to get css class for the item given
  getColourClass(current: Gear, bis: Gear): string {
    if (current.id === bis.id) return 'is-il-bis'
    if (current.item_level > this.maxItemLevel || current.item_level < this.maxItemLevel - 25) return 'is-il-out-of-range'
    return `is-il-minus-${this.maxItemLevel - current.item_level}`
  }

  // Tab toggling methods
  toggleLeft(): void {
    this.hideTabs()
    this.rightShown = false
    if (!this.leftShown) {
      this.lhs.classList.remove('is-hidden')
      this.lhsTab.classList.add('is-active')
      this.leftShown = true
    }
    else {
      this.leftShown = false
    }
  }

  toggleRight(): void {
    this.hideTabs()
    this.leftShown = false
    if (!this.rightShown) {
      this.rhs.classList.remove('is-hidden')
      this.rhsTab.classList.add('is-active')
      this.rightShown = true
    }
    else {
      this.rightShown = false
    }
  }

  hideTabs(): void {
    this.lhs.classList.add('is-hidden')
    this.rhs.classList.add('is-hidden')
    this.lhsTab.classList.remove('is-active')
    this.rhsTab.classList.remove('is-active')
  }
}
